<template>
  <b-card>
    <b-card-header>
      <b-card-header><b-card-title>Edit FAQ</b-card-title></b-card-header>
    </b-card-header>
    <b-card-body v-if="faq">
      <label for="question" class="h5">Question</label>
      <b-form-input id="question" v-model="faq.question"></b-form-input>
      <label for="answer" class="mt-3 h5">Answer</label>
      <b-form-input id="answer" v-model="faq.answer"></b-form-input>
      <b-button class="btn btn-success mt-2" @click="onSave">Save</b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { fetchFaq } from "@/services/api/faq";
import { apiErrorHandler } from "@/services/utils/util";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      faq: null,
    };
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormInput,
    BButton,
  },
  mounted() {
    this.onFetchFaqData();
  },
  created() {
    this.$watch(
      () => this.onFetchFaqData(),
      () => this.$route.params.id
    );
  },
  methods: {
    ...mapActions({
      updateFaq: "faq/updateFaq",
    }),
    async onFetchFaqData() {
      try {
        this.faq = await fetchFaq(this.$route.params.id);
      } catch (e) {
        apiErrorHandler(e);
        this.$router.go(-1);
      }
    },
    onSave() {
      try {
        this.updateFaq({ id: this.faq.id, ...this.faq });
      } finally {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style></style>
